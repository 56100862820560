.projects__container {
  margin: 15vh auto;
}

.projects__wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto, 100%);
  align-items: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;

  margin: 0 auto;
  max-width: 65ch;
  border-top: 1px dotted rgb(175, 174, 174);
  border-right: 1px dotted rgb(175, 174, 174);
}

.projects__label {
  width: 85ch;
  text-align: left;
  margin: 0 auto 0 30px;

  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: normal;
  font-size: 14px;

  padding: 15px 0;
  color: #444444;
}
@media only screen and (min-width: 800px) {
  .projects__label {
    margin: 0 auto;
  }
}

.projects__project_date {
  position: absolute;
  right: 0;
  padding: 15px;
  margin: 15px;

  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: normal;
  font-size: 12px;
  color: #888888;
}
