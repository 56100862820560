.nav__wrapper {
  position: sticky;
  top: 0;
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  margin: 0;
  padding: 30px;
}

@media only screen and (min-width: 800px) {
  .nav__wrapper {
    margin: 0 45px;
  }
}

:any-link {
  text-decoration: none;
}

.nav__pages a,
.nav__pages a:visited {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  /* font-weight: normal; */
  font-size: 16px;
  color: black;
  margin: 0 0 0 45px;
  padding: 5px;
}

@media only screen and (min-width: 800px) {
  .nav__pages a,
  .nav__pages a:visited {
    margin: 0 30px;
  }
}

.nav__pages .active::after {
  content: ' • ';
  position: absolute;
  left: -20px;
  top: -5px;
  padding: 5px;
  color: rgb(255, 0, 111);
}

.nav__home,
.nav__home:visited {
  position: relative;
  color: black;
  font-size: 24px;
}
.nav__pages a:hover,
:any-link:hover,
footer a p:hover {
  color: rgb(255, 0, 111);
}

.nav__pages a:focus,
footer a:focus,
:any-link:focus {
  outline: 1.5px solid rgb(255, 0, 111);
}
