.notfound__wrapper {
  max-width: 20ch;
  height: 40vh;
  text-align: center;
  margin: 25vh auto 0 auto;
}

.notfound__btn {
  height: 80px;
  width: 12ch;
  padding: 5px;
  background-color: black;
  color: #efefef;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.notfound__btn:hover {
  transform: scale(1.03);
}
.notfound__btn:focus {
  box-shadow: 0 0 0 8px #efefef, 0px 0px 0px 10.5px rgb(255, 0, 111);
  border-radius: 0;
}
