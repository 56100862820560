.homepage__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homepage__featured__label {
  width: 85ch;
  max-width: 85vw;
  text-align: left;
  margin: 0 auto 0 30px;

  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: normal;
  font-size: 14px;

  padding: 15px 0;
  color: #444444;
}
@media only screen and (min-width: 800px) {
  .homepage__featured__label {
    margin: 0 auto;
  }
}
