.intro__wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 30px;
  margin: 15vh 0 0 0;
}
.intro__content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 65ch;
  margin: 0 auto;
}

.intro__content h1 {
  flex: 0 0 100%;
  font-size: 46px;
  margin: 0 auto 45px auto;
  line-height: 1.2;
  text-align: left;
}

.intro__content h2 {
  font-size: 20px;
  font-weight: 500;
  padding: 30px 0;
  margin: 0 0 30px 0;
}

@media only screen and (min-width: 500px) {
  .intro__content h2 {
    flex: 0 0 45%;
  }
}

@media only screen and (min-width: 800px) {
  .intro__content h3 {
    flex: 0 0 45%;
  }
}

.intro__content blockquote {
  margin: 15px 0 30px 0;
  border: 1px dotted rgb(175, 174, 174);
}

@media only screen and (min-width: 800px) {
  .intro__content blockquote {
    margin: 300px 0 30px 0;
  }
}

.intro__content p img {
  width: 87vw;
  margin: 0 auto;
  /* border: 1px dotted rgb(175, 174, 174); */
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.2);

  background: linear-gradient(
      67.09deg,
      rgba(217, 250, 28, 0.9) 3%,
      rgba(252, 255, 101, 0) 37.99%
    ),
    linear-gradient(132.34deg, #f59e6c 25.23%, rgba(70, 255, 188, 0.08) 60.88%),
    linear-gradient(
      155.09deg,
      #263aaa 1.14%,
      rgba(245, 133, 208, 0.86) 13.44%,
      rgba(196, 141, 233, 0.86) 72.8%,
      rgba(15, 12, 182, 0.58) 99.02%
    );
}

.intro__content p img:hover {
  background: linear-gradient(
      77.09deg,
      rgba(195, 250, 93, 0.9) 0%,
      rgba(252, 255, 101, 0) 37.99%
    ),
    linear-gradient(132.34deg, #7398e7 25.23%, rgba(70, 255, 188, 0.08) 60.88%),
    linear-gradient(
      155.09deg,
      #83f0ca 1.14%,
      rgba(139, 98, 214, 0.86) 33.44%,
      rgba(236, 180, 121, 0.86) 62.8%,
      rgba(228, 72, 72, 0.58) 99.02%
    );
}

@media only screen and (min-width: 500px) {
  .intro__content p img {
    width: 35vw;
    margin: 15px 0;
  }
}

@media only screen and (min-width: 1100px) {
  .intro__content p img {
    width: 390px;
    margin: 15px 0;
  }
}

.intro__content:first-child {
  margin: 45px auto;
}

.intro__blockquote {
  padding: 15px 30px;
}
