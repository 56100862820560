.footer__container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 15vh auto 30px auto;
  padding: 30px 15px;
  max-width: 100vw;
}

@media only screen and (min-width: 900px) {
  .footer__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;
    margin: 15vh 75px 30px 75px;
  }
}

.footer__container p,
.footer__socials__item {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 16px;
  color: black;
  line-height: 1.6;
}

.footer__copyright {
  display: block;
  margin: 0 15px;
}

.footer__socials__wrapper {
  list-style: none;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  padding: 0;
}
@media only screen and (min-width: 400px) {
  .footer__socials__wrapper {
    flex-flow: row nowrap;
    align-items: baseline;
  }
}

@media only screen and (min-width: 800px) {
  .footer__socials__wrapper {
    margin: 0 0 15px 0;
  }
}

.footer__socials__item {
  margin: 15px;
  padding: 5px;
}

.footer__contact p {
  margin: 15px;
  padding: 5px;
}
