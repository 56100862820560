@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css');

.project__details__title {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  /* align-content: flex-end; */
  align-items: flex-start;
  padding: 45px 21vw 30px 45px;
  margin: 100px 0 0 0;
  flex: 0 1 35%;
  max-width: 450px;
}

@media only screen and (min-width: 1400px) {
  .project__details__title {
    padding: 45px 21vw 30px 45px;
  }
}

.project__details__title h1 {
  font-size: 40px;
  margin: 0;
}

@media only screen and (min-width: 1000px) {
  .project__details__title h1 {
    font-size: calc(25px + 30 * ((60vw - 100px) / 1025));
    margin: 0;
  }
}

.project__details__meta__wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-content: flex-start;

  border-top: 1px dotted rgb(175, 174, 174);
  border-bottom: 1px dotted rgb(175, 174, 174);
}

@media only screen and (min-width: 800px) {
  .project__details__meta__wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }
}

.project__details__meta {
  border-left: 1px dotted rgb(175, 174, 174);
  flex: 0 0 33%;
}

.project__details__meta p {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: normal;
  font-size: 12px;
  color: black;
  margin: 0;
  padding: 0;
}

.project__details__meta__item {
  border-bottom: 1px dotted rgb(175, 174, 174);
  padding: 30px;
}

.project__details__meta__item ul,
span {
  margin: 8.5px;
}

.project__details__meta__item a {
  padding: 8.5px 0 0 0;
}

.project__details__meta__item:last-of-type {
  border-bottom: 1px solid transparent;
}

.project__details__meta__item:first-of-type {
  border-top: 1px dotted rgb(175, 174, 174);
}

@media only screen and (min-width: 955px) {
  .project__details__meta__item:first-of-type {
    border-top: 1px solid transparent;
  }
}
.fa {
  margin: 0 0 0 8.5px;
}

.project__details__meta__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.project__details__meta__listitem {
  display: inline-block;
  margin: 0 8.5px 0 0;
}
.project__details__meta__listitem:after {
  content: ',   ';
}
.project__details__meta__listitem:last-of-type:after {
  content: '   ';
}

.project__details__meta__collaborators {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
}

.project__details__meta__collaborator_link {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 15px 0 5px;
}

.project__details__meta__collaborator {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
}

.project__details__content__wrapper {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  max-width: 90ch;
  margin: 60px auto 0 auto;
  padding: 0 30px;
}

@media only screen and (min-width: 800px) {
  .project__details__content__wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 60px auto 30vw auto;
  }
}

.project__details__stickyimg {
  position: static;
  top: 0px;

  height: 100%;
  padding: 0 15px;
  margin: 0 auto 45px auto;
}

@media only screen and (min-width: 800px) {
  .project__details__stickyimg {
    position: sticky;
    top: 12vh;
    padding: 0 15px;
    max-height: 600px;
  }
}

.project__details__stickyimg img {
  width: 70vw;
  margin: 45px auto 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 30px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 800px) {
  .project__details__stickyimg img {
    max-width: 500px;
    min-width: 300px;
    width: 40vw;
  }
}

.project__details__content {
  max-width: 60ch;
  margin: 0 15px;
  padding: 15px;
  flex: 0 1 50%;
}
