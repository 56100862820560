.about__wrapper {
  max-width: 50ch;
  margin: 100px auto 30px auto;
  padding: 15px;
}

.about__wrapper img {
  display: block;
  width: 250px;

  margin: 45px auto 15px auto;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 600px) {
  .about__wrapper img {
    max-width: 300px;
    margin-top: 60px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.1);
  }
}

.about__label {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: normal;
  font-size: 16px;
  color: black;
  margin: 0 0 60px 0;
}

.about__wrapper p:last-of-type {
  display: block;
  max-width: 250px;
  margin: 0 auto 15px auto;
}
