@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 500;
  background-color: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  position: relative;
  min-height: 100vh;
  text-align: left;
}

.intro__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 30px;
  margin: 15vh 0 0 0;
}
.intro__content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  max-width: 65ch;
  margin: 0 auto;
}

.intro__content h1 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  font-size: 46px;
  margin: 0 auto 45px auto;
  line-height: 1.2;
  text-align: left;
}

.intro__content h2 {
  font-size: 20px;
  font-weight: 500;
  padding: 30px 0;
  margin: 0 0 30px 0;
}

@media only screen and (min-width: 500px) {
  .intro__content h2 {
    -webkit-flex: 0 0 45%;
            flex: 0 0 45%;
  }
}

@media only screen and (min-width: 800px) {
  .intro__content h3 {
    -webkit-flex: 0 0 45%;
            flex: 0 0 45%;
  }
}

.intro__content blockquote {
  margin: 15px 0 30px 0;
  border: 1px dotted rgb(175, 174, 174);
}

@media only screen and (min-width: 800px) {
  .intro__content blockquote {
    margin: 300px 0 30px 0;
  }
}

.intro__content p img {
  width: 87vw;
  margin: 0 auto;
  /* border: 1px dotted rgb(175, 174, 174); */
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.2);

  background: linear-gradient(
      67.09deg,
      rgba(217, 250, 28, 0.9) 3%,
      rgba(252, 255, 101, 0) 37.99%
    ),
    linear-gradient(132.34deg, #f59e6c 25.23%, rgba(70, 255, 188, 0.08) 60.88%),
    linear-gradient(
      155.09deg,
      #263aaa 1.14%,
      rgba(245, 133, 208, 0.86) 13.44%,
      rgba(196, 141, 233, 0.86) 72.8%,
      rgba(15, 12, 182, 0.58) 99.02%
    );
}

.intro__content p img:hover {
  background: linear-gradient(
      77.09deg,
      rgba(195, 250, 93, 0.9) 0%,
      rgba(252, 255, 101, 0) 37.99%
    ),
    linear-gradient(132.34deg, #7398e7 25.23%, rgba(70, 255, 188, 0.08) 60.88%),
    linear-gradient(
      155.09deg,
      #83f0ca 1.14%,
      rgba(139, 98, 214, 0.86) 33.44%,
      rgba(236, 180, 121, 0.86) 62.8%,
      rgba(228, 72, 72, 0.58) 99.02%
    );
}

@media only screen and (min-width: 500px) {
  .intro__content p img {
    width: 35vw;
    margin: 15px 0;
  }
}

@media only screen and (min-width: 1100px) {
  .intro__content p img {
    width: 390px;
    margin: 15px 0;
  }
}

.intro__content:first-child {
  margin: 45px auto;
}

.intro__blockquote {
  padding: 15px 30px;
}

.projectcard__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  min-height: 200px;
  /* max-height: 240px; */
  padding: 30px;
  cursor: pointer;

  border-left: 1px dotted rgb(175, 174, 174);
  border-bottom: 1px dotted rgb(175, 174, 174);
}

.projectcard__wrapper:hover {
  background-color: #f4f4f4;
}

.projectcard__title {
  height: 30px;
  font-size: 26px;
  margin: 0;
  padding: 0;
}

.projectcard__summary {
  height: 60px;
}

.projectcard__stack__wrapper {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.projectcard__stack__wrapper li {
  color: #444;
  font-size: 12px;
  padding: 2px;
  margin: 3px 10px 3px 0;
  border-radius: 0.1rem;
}

.projectcard__stacK__item {
  font-family: Consolas, monospace;
}

.featured__projects__wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto, 100%);
  align-items: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;

  margin: 0 auto;
  width: 100vw;
  border-top: 1px dotted rgb(175, 174, 174);
}

@media only screen and (min-width: 800px) {
  .featured__projects__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto, 100%);
    align-items: flex-start;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    margin: 0 auto;
    width: 65ch;
    border-right: 1px dotted rgb(175, 174, 174);
  }
}

.homepage__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.homepage__featured__label {
  width: 85ch;
  max-width: 85vw;
  text-align: left;
  margin: 0 auto 0 30px;

  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: normal;
  font-size: 14px;

  padding: 15px 0;
  color: #444444;
}
@media only screen and (min-width: 800px) {
  .homepage__featured__label {
    margin: 0 auto;
  }
}

.about__wrapper {
  max-width: 50ch;
  margin: 100px auto 30px auto;
  padding: 15px;
}

.about__wrapper img {
  display: block;
  width: 250px;

  margin: 45px auto 15px auto;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 600px) {
  .about__wrapper img {
    max-width: 300px;
    margin-top: 60px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.1);
  }
}

.about__label {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: normal;
  font-size: 16px;
  color: black;
  margin: 0 0 60px 0;
}

.about__wrapper p:last-of-type {
  display: block;
  max-width: 250px;
  margin: 0 auto 15px auto;
}

.nav__wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
  margin: 0;
  padding: 30px;
}

@media only screen and (min-width: 800px) {
  .nav__wrapper {
    margin: 0 45px;
  }
}

:-webkit-any-link {
  text-decoration: none;
}

:any-link {
  text-decoration: none;
}

.nav__pages a,
.nav__pages a:visited {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  /* font-weight: normal; */
  font-size: 16px;
  color: black;
  margin: 0 0 0 45px;
  padding: 5px;
}

@media only screen and (min-width: 800px) {
  .nav__pages a,
  .nav__pages a:visited {
    margin: 0 30px;
  }
}

.nav__pages .active::after {
  content: ' • ';
  position: absolute;
  left: -20px;
  top: -5px;
  padding: 5px;
  color: rgb(255, 0, 111);
}

.nav__home,
.nav__home:visited {
  position: relative;
  color: black;
  font-size: 24px;
}
:-webkit-any-link:hover {
  color: rgb(255, 0, 111);
}
.nav__pages a:hover,
:any-link:hover,
footer a p:hover {
  color: rgb(255, 0, 111);
}

:-webkit-any-link:focus {
  outline: 1.5px solid rgb(255, 0, 111);
}

.nav__pages a:focus,
footer a:focus,
:any-link:focus {
  outline: 1.5px solid rgb(255, 0, 111);
}

.footer__container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 15vh auto 30px auto;
  padding: 30px 15px;
  max-width: 100vw;
}

@media only screen and (min-width: 900px) {
  .footer__container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    margin: 15vh 75px 30px 75px;
  }
}

.footer__container p,
.footer__socials__item {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 16px;
  color: black;
  line-height: 1.6;
}

.footer__copyright {
  display: block;
  margin: 0 15px;
}

.footer__socials__wrapper {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  margin: auto;
  padding: 0;
}
@media only screen and (min-width: 400px) {
  .footer__socials__wrapper {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: baseline;
            align-items: baseline;
  }
}

@media only screen and (min-width: 800px) {
  .footer__socials__wrapper {
    margin: 0 0 15px 0;
  }
}

.footer__socials__item {
  margin: 15px;
  padding: 5px;
}

.footer__contact p {
  margin: 15px;
  padding: 5px;
}

.project__details__title {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  /* align-content: flex-end; */
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 45px 21vw 30px 45px;
  margin: 100px 0 0 0;
  -webkit-flex: 0 1 35%;
          flex: 0 1 35%;
  max-width: 450px;
}

@media only screen and (min-width: 1400px) {
  .project__details__title {
    padding: 45px 21vw 30px 45px;
  }
}

.project__details__title h1 {
  font-size: 40px;
  margin: 0;
}

@media only screen and (min-width: 1000px) {
  .project__details__title h1 {
    font-size: calc(25px + 30 * ((60vw - 100px) / 1025));
    margin: 0;
  }
}

.project__details__meta__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-content: flex-start;
          align-content: flex-start;

  border-top: 1px dotted rgb(175, 174, 174);
  border-bottom: 1px dotted rgb(175, 174, 174);
}

@media only screen and (min-width: 800px) {
  .project__details__meta__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
}

.project__details__meta {
  border-left: 1px dotted rgb(175, 174, 174);
  -webkit-flex: 0 0 33%;
          flex: 0 0 33%;
}

.project__details__meta p {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: normal;
  font-size: 12px;
  color: black;
  margin: 0;
  padding: 0;
}

.project__details__meta__item {
  border-bottom: 1px dotted rgb(175, 174, 174);
  padding: 30px;
}

.project__details__meta__item ul,
span {
  margin: 8.5px;
}

.project__details__meta__item a {
  padding: 8.5px 0 0 0;
}

.project__details__meta__item:last-of-type {
  border-bottom: 1px solid transparent;
}

.project__details__meta__item:first-of-type {
  border-top: 1px dotted rgb(175, 174, 174);
}

@media only screen and (min-width: 955px) {
  .project__details__meta__item:first-of-type {
    border-top: 1px solid transparent;
  }
}
.fa {
  margin: 0 0 0 8.5px;
}

.project__details__meta__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.project__details__meta__listitem {
  display: inline-block;
  margin: 0 8.5px 0 0;
}
.project__details__meta__listitem:after {
  content: ',   ';
}
.project__details__meta__listitem:last-of-type:after {
  content: '   ';
}

.project__details__meta__collaborators {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.project__details__meta__collaborator_link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  margin: 0 15px 0 5px;
}

.project__details__meta__collaborator {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.project__details__content__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-width: 90ch;
  margin: 60px auto 0 auto;
  padding: 0 30px;
}

@media only screen and (min-width: 800px) {
  .project__details__content__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 60px auto 30vw auto;
  }
}

.project__details__stickyimg {
  position: static;
  top: 0px;

  height: 100%;
  padding: 0 15px;
  margin: 0 auto 45px auto;
}

@media only screen and (min-width: 800px) {
  .project__details__stickyimg {
    position: -webkit-sticky;
    position: sticky;
    top: 12vh;
    padding: 0 15px;
    max-height: 600px;
  }
}

.project__details__stickyimg img {
  width: 70vw;
  margin: 45px auto 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 30px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 800px) {
  .project__details__stickyimg img {
    max-width: 500px;
    min-width: 300px;
    width: 40vw;
  }
}

.project__details__content {
  max-width: 60ch;
  margin: 0 15px;
  padding: 15px;
  -webkit-flex: 0 1 50%;
          flex: 0 1 50%;
}

.notfound__wrapper {
  max-width: 20ch;
  height: 40vh;
  text-align: center;
  margin: 25vh auto 0 auto;
}

.notfound__btn {
  height: 80px;
  width: 12ch;
  padding: 5px;
  background-color: black;
  color: #efefef;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: -webkit-transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.notfound__btn:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.notfound__btn:focus {
  box-shadow: 0 0 0 8px #efefef, 0px 0px 0px 10.5px rgb(255, 0, 111);
  border-radius: 0;
}

.projects__container {
  margin: 15vh auto;
}

.projects__wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto, 100%);
  align-items: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;

  margin: 0 auto;
  max-width: 65ch;
  border-top: 1px dotted rgb(175, 174, 174);
  border-right: 1px dotted rgb(175, 174, 174);
}

.projects__label {
  width: 85ch;
  text-align: left;
  margin: 0 auto 0 30px;

  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: normal;
  font-size: 14px;

  padding: 15px 0;
  color: #444444;
}
@media only screen and (min-width: 800px) {
  .projects__label {
    margin: 0 auto;
  }
}

.projects__project_date {
  position: absolute;
  right: 0;
  padding: 15px;
  margin: 15px;

  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: normal;
  font-size: 12px;
  color: #888888;
}

